import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  OwnUpIcon,
  ownUpLightTheme,
  OwnUpSmallSubheadlineMedium,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React from 'react';
import {
  IconMultipleBody,
  IconMultipleHeadline,
  IconMultipleSection,
  IconMultipleSectionHeader
} from './elements';

export interface IconMultiple {
  sectionHeader?: JSX.Element;
  ariaLabel: string;
  theme?: typeof ownUpLightTheme;
  valueProps: {
    icon: JSX.Element;
    header: string;
    body: string;
  }[];
}

export const IconMultipleInner = ({
  valueProps,
  ariaLabel
}: Pick<IconMultiple, 'valueProps' | 'ariaLabel'>) => (
  <OwnUpGridContainer variant="legacy" justifyContent="center">
    {valueProps.map(({ icon, header, body }, key) => (
      <OwnUpGridItem md={4} xs={12} key={ariaLabel + '-' + key}>
        <OwnUpGridContainer variant="legacy">
          <OwnUpGridOffset lg={1} md={0} sm={1} xs={0} />
          <OwnUpGridItem lg={10} md={12} sm={10} xs={12}>
            <OwnUpIcon icon={icon} style={{ justifyContent: 'center' }} height="64px" />
            <IconMultipleHeadline>
              <OwnUpSmallSubheadlineMedium variant={'h2'}>{header}</OwnUpSmallSubheadlineMedium>
            </IconMultipleHeadline>
            <IconMultipleBody>{body}</IconMultipleBody>
          </OwnUpGridItem>
          <OwnUpGridOffset lg={1} md={0} sm={1} xs={0} />
        </OwnUpGridContainer>
      </OwnUpGridItem>
    ))}
  </OwnUpGridContainer>
);

export const IconMultiple = ({
  sectionHeader,
  ariaLabel,
  theme = ownUpLightTheme,
  valueProps
}: IconMultiple) => (
  <OwnUpThemeProvider theme={theme}>
    <IconMultipleSection aria-label={ariaLabel}>
      <OwnUpGridWrapper>
        {sectionHeader && <IconMultipleSectionHeader>{sectionHeader}</IconMultipleSectionHeader>}
        <IconMultipleInner valueProps={valueProps} ariaLabel={ariaLabel} />
      </OwnUpGridWrapper>
    </IconMultipleSection>
  </OwnUpThemeProvider>
);
