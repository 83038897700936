import { OwnUpSmallHeadlineBook } from '@rategravity/own-up-component-library';
import React from 'react';
import {
  howItWorksHeader,
  howItWorksIconMultipleValueProps
} from '../../../data/content/real-estate';
import { IconMultiple } from '../../icon-multiple';
import { SectionSubtitle } from './elements';

export const HowItWorks = () => (
  <IconMultiple
    ariaLabel="How it works"
    sectionHeader={
      <React.Fragment>
        <OwnUpSmallHeadlineBook variant="h2">{howItWorksHeader.title}</OwnUpSmallHeadlineBook>
        <SectionSubtitle>
          {howItWorksHeader.subtitle[0]}
          <br />
          {howItWorksHeader.subtitle[1]}
        </SectionSubtitle>
      </React.Fragment>
    }
    valueProps={howItWorksIconMultipleValueProps}
  />
);
