import React from 'react';
import { LightbulbLargeIcon, TalkLargeIcon, TransparentSVG } from '../../images/icons/reskin';

export const howItWorksHeader = {
  title: 'How it works',
  subtitle: [
    'We use technology and dedicated human advisors to simplify the lending process.',
    'We deliver clarity and better outcomes for home buyers and their agents.'
  ]
};

export const howItWorksIconMultipleValueProps = [
  {
    icon: <TransparentSVG />,
    header: 'Human connection',
    body: 'Your client creates a profile and is matched with an experienced Home Advisor to guide them (and support you) through the entire home buying process'
  },
  {
    icon: <LightbulbLargeIcon />,
    header: 'Technology',
    body: "Our technology evaluates your buyer's profile and goals to determine the optimal lender and financing plan to achieve a seamless closing"
  },
  {
    icon: <TalkLargeIcon />,
    header: 'Collaboration',
    body: "It takes a team to meet your client's goals. Our Home Advisors are here to support you and your client day-and-night all the way through closing (we're available by text, phone, or email)"
  }
];

export const agentsImageMultipleValueProps = [
  {
    image: 'claudel.jpg',
    data: {
      header: 'Claudel Frederique',
      body: '"Own Up is able to really get aggressive with rates... they always jump in on the issues immediately...they never leave us hanging."',
      italicText: 'Bentley Real Estate, Western, MA',
      quote: undefined
    }
  },
  {
    image: 'courtney.jpg',
    data: {
      header: 'Courtney Lott',
      body: '"Own Up worked tirelessly to ensure my clients were taken care of and received the best deal possible...they are responsive, dedicated, diligent."',
      italicText: 'Keller Williams, Atlanta, GA',
      quote: undefined
    }
  },
  {
    image: 'brian.jpg',
    data: {
      header: 'Brian Head',
      body: '"I have been amazed at the rates they have been able to get for my clients and myself on investment properties...the professionalism and responsiveness are unmatched in the mortgage industry...I have gotten this same feedback from my clients as well."',
      italicText: 'Buckhorn Realty, San Antonio, TX',
      quote: undefined
    }
  }
];

export const forYouImageMultipleValueProps = [
  {
    image: 'shaking-hands.jpg',
    data: {
      header: 'Close with confidence',
      body: 'We evaluate multiple financing options and determine a solution that sets your client up for success',
      italicText: undefined,
      quote: undefined
    }
  },
  {
    image: 'k-at-laptop.jpg',
    data: {
      header: 'Technology-enabled partner',
      body: 'Buyers can generate preapproval letters on-demand from their phone and our Home Advisors are always available',
      italicText: undefined,
      quote: undefined
    }
  },
  {
    image: 'happy-couple-smiling.jpg',
    data: {
      header: 'A happy customer',
      body: 'Customers close with certainty and save money while doing it. We have thousands of reviews to show for it',
      italicText: undefined,
      quote: undefined
    }
  }
];
