import { PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const IconMultipleSection = styled.section`
  /* Fall back on primary if no secondary bg is defined (white theme only) */
  background-color: ${({ theme }: PropsWithTheme<{}>) =>
    theme.backgroundColors.primaryTheme?.backgroundColor};
  background-color: ${({ theme }: PropsWithTheme<{}>) =>
    theme.backgroundColors.tertiaryTheme?.backgroundColor};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;

export const IconMultipleSectionHeader = styled.div`
  padding-bottom: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(6)}px`};
  text-align: center;
`;

export const IconMultipleHeadline = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2.5)}px`};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(3)}px`};
  }
  text-align: center;
`;

export const IconMultipleBody = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2, 0, 3)};
  }
  text-align: center;
`;
