import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const RealEstateHeaderImage = () => (
  <StaticImage
    src="../../images/mike-and-patrick-in-park-arch.png"
    alt="Own Up Co-founders Patrick and Mike stand side by side in a park and smile at the camera"
    loading="eager"
    quality={100}
    placeholder="blurred"
  />
);

const Claudel = () => (
  <StaticImage
    src="../../images/claudel.jpg"
    alt="Real estate agent Claudel Frederique smiles at the camera"
    aspectRatio={1}
    imgStyle={{ borderRadius: '50%' }}
    quality={100}
    placeholder="blurred"
  />
);

const Courtney = () => (
  <StaticImage
    src="../../images/courtney.jpg"
    alt="Real estate agent Courtney Lott smiles at the camera"
    aspectRatio={1}
    imgStyle={{ borderRadius: '50%' }}
    quality={100}
    placeholder="blurred"
  />
);

const Brian = () => (
  <StaticImage
    src="../../images/brian.jpg"
    alt="Real estate agent Brian Head smiles at the camera"
    aspectRatio={1}
    imgStyle={{ borderRadius: '50%' }}
    quality={100}
    placeholder="blurred"
  />
);

export const agentImages = {
  'claudel.jpg': <Claudel />,
  'courtney.jpg': <Courtney />,
  'brian.jpg': <Brian />
};

const ShakingHands = () => (
  <StaticImage
    src="../../images/shaking-hands.jpg"
    alt="Two people shaking hands at the end of a meeting"
    aspectRatio={1}
    imgStyle={{ borderRadius: '50%' }}
    quality={100}
    placeholder="blurred"
  />
);

const K = () => (
  <StaticImage
    src="../../images/k-at-laptop.jpg"
    alt="Own Up Employee K, working at a laptop"
    aspectRatio={1}
    imgStyle={{ borderRadius: '50%' }}
    quality={100}
    placeholder="blurred"
  />
);

const HappyCouple = () => (
  <StaticImage
    src="../../images/happy-couple-smiling.jpg"
    alt="Two happy Own Up customers smile at the camera"
    aspectRatio={1}
    imgStyle={{ borderRadius: '50%' }}
    quality={100}
    placeholder="blurred"
  />
);

export const forYouImages = {
  'shaking-hands.jpg': <ShakingHands />,
  'k-at-laptop.jpg': <K />,
  'happy-couple-smiling.jpg': <HappyCouple />
};
